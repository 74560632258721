import React from "react"
import { animated, config, useSpring, useTransition } from "react-spring"
import { useDrag } from "react-use-gesture"
import { BsFillCircleFill } from "react-icons/bs"

import useHeight from "@src/hooks/use-height"

import classNames from "@src/utilities/class-names"
import optimizeImage from "@src/utilities/optimize-image"

export interface ReviewsSliderProps {
  content?: {
    container_width?: {
      containerWidth?: string
    }
    flourish_image_left?: {
      filename?: string
    }
    flourish_image_right?: {
      filename?: string
    }
    headline?: string
    reviews?: {
      _uid: string
      review_content?: string
      reviewer_name?: string
    }[]
  }
}

export default function ReviewsSlider({ content }: ReviewsSliderProps) {
  const [heightRef, height] = useHeight()

  const [currentSlide, setCurrentSlide] = React.useState<number>(0)

  const parallaxStyles = useSpring({
    from: {
      y: 0,
    },
    to: {
      y: 100,
    },
    config: {
      duration: 30000,
    },
    loop: { reverse: true },
  })

  const reverseParallaxStyles = useSpring({
    from: {
      y: 0,
    },
    to: {
      y: -50,
    },
    config: {
      duration: 30000,
    },
    loop: { reverse: true },
  })

  const transitions = useTransition(
    content?.reviews
      ? content.reviews[currentSlide]
      : { _uid: "", review_content: "", reviewer_name: "" },
    {
      key: content?.reviews ? content.reviews[currentSlide]._uid : "",
      from: {
        opacity: 0,
        transform: "translate3d(10%, 0 0)",
      },
      enter: {
        opacity: 1,
        transform: "translate3d(0%, 0 0)",
      },
      leave: {
        opacity: 0,
        position: "absolute",
        transform: "translate3d(-10%, 0 0)",
      },
    }
  )

  const goToNextSlide = () =>
    setCurrentSlide((prevState) =>
      prevState === content!.reviews!.length - 1 ? 0 : prevState + 1
    )

  const goToPreviousSlide = () =>
    setCurrentSlide((prevState) =>
      prevState === 0 ? content!.reviews!.length - 1 : prevState - 1
    )

  const bindDrag = useDrag(
    ({ down, movement: [mx] }) => {
      if (down) {
        return
      }
      if (mx < 0) {
        goToPreviousSlide()
      } else {
        goToNextSlide()
      }
    },
    { axis: "x" }
  )

  const reviewContainerStyles = useSpring({
    config: { ...config.stiff },
    from: { height: 0 },
    to: {
      height,
    },
  })

  return (
    <section
      className="bg-moss-green/10 text-moss-green-600 relative mb-8 py-24 md:mb-16"
      data-component="reviews-slider"
    >
      <div
        className={`container-${
          content?.container_width?.containerWidth ?? "tablet"
        }`}
      >
        {content?.headline && (
          <h4
            className="text-center text-lg tracking-wide uppercase mb-1"
            dangerouslySetInnerHTML={{ __html: content.headline }}
          />
        )}
        <animated.div className="relative" style={{ ...reviewContainerStyles }}>
          <div
            className="overflow-hidden cursor-pointer select-none"
            ref={heightRef}
          >
            {transitions((transitionStyles, review) => {
              return (
                <animated.div
                  {...bindDrag()}
                  className="overflow-hidden static text-center"
                  key={review?._uid}
                  style={transitionStyles}
                >
                  <p
                    className="font-serif text-2xl"
                    dangerouslySetInnerHTML={{
                      __html: review?.review_content ?? "",
                    }}
                  />
                  <cite
                    className="inline-block font-serif text-lg font-extrabold tracking-wide relative mt-2 pt-2"
                    dangerouslySetInnerHTML={{
                      __html: review?.reviewer_name ?? "",
                    }}
                  />
                </animated.div>
              )
            })}
            {content?.reviews && content?.reviews?.length > 1 && (
              <ul className="text-xs list-none mt-8 text-center">
                {content.reviews.map((review, idx) => (
                  <li
                    className={classNames(
                      "inline-block text-xs transition-colors",
                      idx > 0 ? "ml-2" : ""
                    )}
                    key={`indicator-${idx}`}
                    data-reviews-slider-slide-active={idx === currentSlide}
                  >
                    <BsFillCircleFill />
                  </li>
                ))}
              </ul>
            )}
          </div>
        </animated.div>
      </div>
      {content?.flourish_image_left?.filename &&
        content?.flourish_image_left?.filename?.length > 0 && (
          <animated.div
            className="bg-right bg-contain bg-no-repeat absolute w-52 z-[-1] lg:z-[1] before:content-[''] before:block before:pt-[100%] bottom-0 left-0"
            style={{
              backgroundImage: `url(${optimizeImage(
                content.flourish_image_left.filename,
                300
              )})`,
              ...parallaxStyles,
            }}
          />
        )}
      {content?.flourish_image_right?.filename &&
        content?.flourish_image_right?.filename?.length > 0 && (
          <animated.div
            className="bg-left bg-contain bg-no-repeat absolute w-52 z-[-1] lg:z-[1] before:content-[''] before:block before:pt-[100%] right-0 top-0"
            style={{
              backgroundImage: `url(${optimizeImage(
                content.flourish_image_right.filename,
                300
              )})`,
              ...reverseParallaxStyles,
            }}
          />
        )}
    </section>
  )
}
