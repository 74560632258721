import React from "react"
import { BsPlusCircle } from "react-icons/bs"
import Storyblok, { Richtext } from "storyblok-js-client"

export interface FAQItemProps {
  content?: {
    answer?: Richtext
    question?: string
  }
}
export default function FAQItem({ content }: FAQItemProps) {
  const [open, setOpen] = React.useState<boolean>(false)

  return (
    <div
      className="border-gray-300 border-b pb-2 pt-4"
      data-faq-open={open}
      data-component="faq-item"
    >
      <button
        className="bg-transparent border-0 block outline-none text-left w-full"
        onClick={() => setOpen((prevState) => !prevState)}
      >
        <h3
          className="flex items-start justify-between text-xl"
          data-faq-open={open}
        >
          <span className="font-bold text-orange pr-4 flex-[0_0_2rem] md:flex-[0_0_4rem]">
            Q.
          </span>
          <span className="flex font-bold text-left flex-1">
            {content?.question}
          </span>
          <span className="pl-2 flex-[0_0_2rem] md:flex-[0_0_4rem] [&>svg]:transition-transform">
            <BsPlusCircle data-faq-open={open} />
          </span>
        </h3>
      </button>
      <div
        className="flex items-start justify-between"
        data-faq-answer-open={open}
      >
        <h3 className="font-bold text-xl flex items-start justify-between text-mint-ish pr-4 flex-[0_0_2rem] md:flex-[0_0_4rem]">
          A.
        </h3>
        {content?.answer && (
          <div
            className="flex text-left flex-1"
            dangerouslySetInnerHTML={{
              __html: new Storyblok({}).richTextResolver.render(content.answer),
            }}
          />
        )}
      </div>
    </div>
  )
}
