import { BsPlus } from "react-icons/bs"
import { useSpring, animated } from "react-spring"
import StoryblokClient from "storyblok-js-client"

import optimizeImage from "@src/utilities/optimize-image"
import urlFromStoryblokLinkBlok from "@src/utilities/url-from-storyblok-link-blok"

import Button from "@src/components/Button"

import classNames from "@src/utilities/class-names"

export interface SplitHeaderProps {
  content: {
    contain_featured_image: boolean
    container_width?: {
      containerWidth?: string
    }
    cta_button?: any[]
    description: {
      content: any[]
    }
    featured_image: {
      filename: string
    }
    flourish_image: {
      filename: string
    }
    stamp_image: {
      filename: string
    }
    image_on_left: boolean
    super_title: boolean
    title: string
  }
}

export default function SplitHeader({ content }: SplitHeaderProps) {
  const parallaxStyles = useSpring({
    from: {
      y: 0,
    },
    to: {
      y: 50,
    },
    config: {
      duration: 20000,
    },
    loop: { reverse: true },
  })

  return (
    <div
      className={`container-${
        content.container_width?.containerWidth ?? "website"
      }`}
      data-component="split-header"
    >
      <div
        className={classNames(
          "mb-16 relative lg:flex",
          content.image_on_left ? "lg:flex-row-reverse" : "lg:flex-row"
        )}
        data-split-header-reversed={content.image_on_left}
        data-contain-image={content.contain_featured_image}
        data-super-title={content.super_title}
      >
        <div
          className="bg-center bg-cover bg-no-repeat mb-4 lg:flex-1 lg:mb-0 before:content-[''] before:block before:pt-[52.59259%]"
          style={{
            backgroundImage: `url(${optimizeImage(
              content.featured_image.filename,
              2400
            )})`,
          }}
        />
        <div className="relative lg:self-center lg:flex-1">
          <div
            className={classNames(
              "pl-4 relative md:pl-8 lg:pb-16 lg:pt-16",
              !content?.image_on_left ? "lg:ml-8" : "lg:pr-8 lg:pl-8"
            )}
          >
            <div className="absolute font-bold -left-4 text-3xl lg:text-5xl lg:-mt-2">
              <BsPlus />
            </div>
            {content.super_title ? (
              <h2
                className="font-extrabold leading-normal text-3xl lg:text-7xl uppercase"
                dangerouslySetInnerHTML={{ __html: content.title }}
              />
            ) : (
              <h1
                className="font-extrabold leading-normal text-3xl lg:text-5xl uppercase"
                dangerouslySetInnerHTML={{ __html: content.title }}
              />
            )}
            {content?.stamp_image?.filename && (
              <div className="absolute top-0 right-4 max-w-[6em]">
                <img src={content.stamp_image.filename} />
              </div>
            )}
            <div
              className="prose prose-xl"
              dangerouslySetInnerHTML={{
                __html: new StoryblokClient({}).richTextResolver.render(
                  content.description
                ),
              }}
            />
            {content.cta_button && content.cta_button.length === 1 && (
              <div className="mt-8">
                <Button
                  url={urlFromStoryblokLinkBlok(
                    content.cta_button[0].button_url
                  )}
                  text={content.cta_button[0].button_text}
                  type={content.cta_button[0].button_style}
                />
              </div>
            )}
          </div>
        </div>
        {content.flourish_image && (
          <animated.div
            className={classNames(
              "absolute bg-right bg-contain bg-no-repeat -right-4 top-0 z-[-1] w-26 before:content-[''] before:block before:pt-[100%] lg:w-52",
              content.image_on_left
                ? "lg:bg-right lg:left-auto lg:right-full"
                : "lg:bg-left lg:left-full lg:right-auto"
            )}
            style={{
              backgroundImage: `url(${optimizeImage(
                content.flourish_image.filename,
                300
              )})`,
              ...parallaxStyles,
            }}
          />
        )}
      </div>
    </div>
  )
}
