import styles from "./index.module.scss"

export interface ProfessionalReviewProps {
  content?: {
    author_image?: {
      filename?: string
    }
    author_name?: string
    author_title?: string
    container_width?: {
      containerWidth?: string
    }
    review_text?: string
  }
}

export default function ProfessionalReview({
  content,
}: ProfessionalReviewProps) {
  return (
    <section
      className={
        content?.container_width?.containerWidth
          ? `container-${content.container_width.containerWidth}`
          : "container-tablet"
      }
    >
      <div className={styles.wrapper}>
        {content?.review_text && (
          <blockquote className={styles.quote}>
            <span
              className={styles.quoteMark}
              dangerouslySetInnerHTML={{ __html: "&ldquo;" }}
            />
            <p dangerouslySetInnerHTML={{ __html: content.review_text }} />
            {(content.author_name || content.author_title) && (
              <cite>
                {content.author_name && (
                  <span
                    className={styles.citeName}
                    dangerouslySetInnerHTML={{ __html: content.author_name }}
                  />
                )}
                {content.author_title && (
                  <span
                    className={styles.citeTitle}
                    dangerouslySetInnerHTML={{ __html: content.author_title }}
                  />
                )}
              </cite>
            )}
          </blockquote>
        )}
        {content?.author_image?.filename && (
          <div
            className={styles.citeImage}
            style={{ backgroundImage: `url(${content.author_image.filename})` }}
          />
        )}
      </div>
    </section>
  )
}
