export type CmsLinkBlok = {
  story: {
    url: string
    [key: string]: any
  }
  url: string
  [key: string]: any
}
export default function urlFromStoryblokLinkBlok(
  linkBlok: CmsLinkBlok
): string {
  if (linkBlok.url !== "") {
    return linkBlok.url
  }
  return `/${linkBlok.cached_url}`
}
