import React from "react"

export interface WYSIWYGContentProps {
  content: onyx.Types.StoryblokContent
}
export default function KlaviyoForm({ content }: WYSIWYGContentProps) {
  return (
    <div className="mx-auto max-w-md" data-component="klaviyo-form">
      <div className={`klaviyo-form-${content.form_id}`}></div>
    </div>
  )
}
