import StoryblokClient, { Richtext } from "storyblok-js-client"

import styles from "./index.module.scss"

export interface IngredientsListProps {
  content?: {
    container_width?: {
      containerWidth?: string
    }
    content?: Richtext
    headline?: string
    items?: {
      _uid: string
      icon?: {
        filename?: string
      }
      text?: Richtext
    }[]
  }
}

export default function IngredientsList({ content }: IngredientsListProps) {
  return (
    <section
      className={
        content?.container_width?.containerWidth
          ? `container-${content?.container_width?.containerWidth}`
          : "container-website"
      }
    >
      <div className={styles.inner}>
        {content?.headline && (
          <h2 dangerouslySetInnerHTML={{ __html: content.headline }} />
        )}
        <div className={styles.iconsContainer}>
          {content?.items?.map((item) => (
            <div className={styles.iconItem} key={item._uid}>
              {item.icon?.filename && (
                <div className={styles.icon}>
                  <img src={item.icon.filename} />
                </div>
              )}
              {item.text && (
                <div
                  className={styles.text}
                  dangerouslySetInnerHTML={{
                    __html: new StoryblokClient({}).richTextResolver.render(
                      item.text
                    ),
                  }}
                />
              )}
            </div>
          ))}
        </div>
        {content?.content && (
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{
              __html: new StoryblokClient({}).richTextResolver.render(
                content.content
              ),
            }}
          />
        )}
      </div>
    </section>
  )
}
