import React from "react"
import { useSpring, animated } from "react-spring"

import classNames from "@src/utilities/class-names"

export interface TabbedListProps {
  content?: {
    container_width?: {
      containerWidth?: string
    }
    featured_image?: {
      filename?: string
    }
    flourish_image?: {
      filename?: string
    }
    headline?: string
    preheadline?: string
    items?: TabbedListTab[]
  }
}

export type TabbedListTab = {
  steps?: { step: string }[]
  name?: string
}

function textToId(text: string): string {
  return text
    .toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-")
}

export default function TabbedList({ content }: TabbedListProps) {
  const [activeTab, setActiveTab] = React.useState<number>(0)

  const parallaxStyles = useSpring({
    from: {
      y: 0,
    },
    to: {
      y: 50,
    },
    config: {
      duration: 30000,
    },
    loop: { reverse: true },
  })

  return (
    <div className="overflow-x-hidden" data-component="tabbed-list">
      <section
        className={`container-${
          content?.container_width?.containerWidth ?? "laptop"
        }`}
      >
        <div className="mb-8 relative lg:flex lg:flex-row-reverse lg:16">
          <div
            className="bg-center bg-cover bg-[url] mx-auto mb-8 md:flex-[1_0_50%] before:content-[''] before:block before:pt-[100%] md:before:hidden"
            style={{
              backgroundImage: `url(${content?.featured_image?.filename})`,
            }}
          />
          <div className="relative md:self-center md:flex-[1_0_50%] md:py-8 lg:py-12">
            <div className="md:pr-8">
              {content?.preheadline && (
                <h4
                  className="italic font-serif mb-4 text-center md:text-left md:mb-6"
                  dangerouslySetInnerHTML={{ __html: content.preheadline }}
                />
              )}
              {content?.headline && (
                <h2
                  className="text-2xl lg:text-4xl font-extrabold mb-4 uppercase text-center md:text-left"
                  dangerouslySetInnerHTML={{ __html: content.headline }}
                />
              )}
              <ul className="list-none mb-8 text-center md:text-left">
                {content?.items?.map((tab, idx) => (
                  <li
                    className={classNames(
                      "inline-block relative after:bg-orange after:content-[''] after:block after:absolute after:h-1 after:left-0 after:top-full after:origin-center after:transition-all",
                      idx > 0 ? "ml-4 lg:ml-8" : ""
                    )}
                    data-tabbed-list-nav-active={activeTab === idx}
                    key={`li-${textToId(tab.name ?? "")}_${idx}`}
                  >
                    <button
                      className="bg-transparent font-sans text-sm font-bold uppercase"
                      dangerouslySetInnerHTML={{ __html: tab.name ?? "" }}
                      onClick={() => setActiveTab(idx)}
                    />
                  </li>
                ))}
              </ul>
              <div className="lg:pr-16">
                {content?.items?.map((tab, idx) => (
                  <div
                    className="hidden"
                    data-tabbed-list-tab-active={activeTab === idx}
                    key={`div-${textToId(tab.name ?? "")}_${idx}`}
                  >
                    <ol className="list-none">
                      {tab.steps?.map((step, tabIdx) => (
                        <li
                          key={`div-${textToId(
                            tab.name ?? ""
                          )}_${idx}_${tabIdx}`}
                          className={classNames(
                            `ml-8 pl-4 relative`,
                            tabIdx > 0 ? "mt-4" : ""
                          )}
                        >
                          <span
                            className="text-base text-orange absolute top-0 right-full"
                            dangerouslySetInnerHTML={{
                              __html:
                                tabIdx < 9 ? `0${tabIdx + 1}` : `${tabIdx + 1}`,
                            }}
                          />
                          <span
                            className="font-sans"
                            dangerouslySetInnerHTML={{ __html: step.step }}
                          />
                        </li>
                      ))}
                    </ol>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {content?.flourish_image?.filename &&
            content?.flourish_image?.filename?.length > 0 && (
              <animated.div
                className="bg-right bg-contain bg-no-repeat absolute -right-16 top-0 z-[-1] w-32 before:block before:content-[''] before:pt-[100%] md:bg-left md:left-[90%] md:right-auto md:w-52"
                style={{
                  backgroundImage: `url(${content.flourish_image.filename})`,
                  ...parallaxStyles,
                }}
              />
            )}
        </div>
      </section>
    </div>
  )
}
