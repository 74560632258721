import React from "react"

import useGtm from "@src/hooks/use-gtm"

import styles from "./index.module.scss"

export interface ButtonProps {
  children?: React.ReactNode
  className?: string[]
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>
  openInNewWindow?: boolean
  size?: "small" | "regular"
  text?: string
  type?:
    | "aquaBlue"
    | "blueSky"
    | "buttonLink"
    | "coral"
    | "deepRose"
    | "forestGreen"
    | "goldenYellow"
    | "greyMd"
    | "greyLt"
    | "inlineLink"
    | "inlineWarningLink"
    | "onyxBlack"
    | "mintIsh"
    | "mossGreen"
    | "paleRose"
    | "pinkOrange"
    | "red"
    | "redOrange"
    | "springGreen"
    | "white"
    | "transparentBlack"
    | "borderBlack"
    | "transparentWhite"
    | "transparentMossGreen"
  url?: string
}

export default function Button({
  children,
  className = [],
  disabled = false,
  onClick,
  openInNewWindow = false,
  size = "regular",
  text,
  type = "onyxBlack",
  url,
}: ButtonProps) {
  const buttonStyles = [
    ...className,
    size === "regular" ? "text-sm" : "text-xs",
    styles[type],
    styles.button,
    styles[size],
  ].join(" ")

  if (url) {
    return (
      <a
        className={buttonStyles}
        data-component="button"
        data-disabled={disabled === true}
        href={url}
        onClick={(e) => {
          useGtm({
            event: "orTrack",
            eventName: "Button Clicked",
            properties: {
              ...(text && { text }),
              ...(type && { style: type }),
              type: "link",
              ...(url && { destination_url: url }),
            },
          })
          if (onClick) {
            onClick(e)
          }
        }}
        target={openInNewWindow ? "_blank" : "_self"}
      >
        {text && <span>{text}</span>}
        {children}
      </a>
    )
  }
  return (
    <button
      className={buttonStyles}
      data-component="button"
      disabled={disabled}
      onClick={(e) => {
        useGtm({
          event: "orTrack",
          eventName: "Button Clicked",
          properties: {
            ...(text && { text }),
            ...(type && { style: type }),
            type: "button",
            ...(url && { destination_url: url }),
          },
        })
        if (onClick) {
          onClick(e)
        }
      }}
      type="button"
    >
      {text && <span>{text}</span>}
      {children}
    </button>
  )
}
