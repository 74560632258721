import React from "react"
import Button from "@src/components/Button"

import { formatCurrency } from "@src/utilities/format-numbers"
import optimizeImage from "@src/utilities/optimize-image"

import type { BundleProductCard } from "@src/entities/models/bundle-product-card"

interface CardPriceProps {
  retailPrice: number
  salePrice?: number | null
}
function CardPrice({ retailPrice, salePrice }: CardPriceProps) {
  if (!retailPrice) {
    return null
  }
  if (salePrice && salePrice > 0 && salePrice < retailPrice) {
    // The product is on sale store-wide
    return (
      <p
        className="font-extrabold mb-2 text-base md:grow"
        dangerouslySetInnerHTML={{
          __html: `${formatCurrency(
            salePrice
          )} <span class="ml-2 font-normal text-sm line-through">${formatCurrency(
            retailPrice
          )}</span>`,
        }}
      />
    )
  }
  return (
    <p
      className="font-extrabold mb-2 text-base md:grow"
      dangerouslySetInnerHTML={{
        __html: formatCurrency(
          salePrice && salePrice > 0 ? salePrice : retailPrice
        ),
      }}
    />
  )
}

export interface BundleCardProps {
  bundle?: BundleProductCard["bundle"]
  storyblokPageId?: string
  url?: string
}
export default function BundleCard({ bundle, url }: BundleCardProps) {
  if (!bundle) {
    return null
  }

  return (
    <div
      className={[
        "group flex flex-col justify-start transition-colors border border-transparent rounded-sm hover:border-gray-300",
      ].join(" ")}
      data-component="bundle-card"
    >
      <a
        href={url}
        aria-label={bundle?.name ?? ""}
        className="bg-center bg-cover block relative before:content-[''] before:block before:pt-[100%]"
        style={{
          ...(bundle.thumbnailUrl && {
            backgroundImage: `url(${optimizeImage(bundle.thumbnailUrl, 600)})`,
          }),
        }}
      >
        <div
          className="bg-center bg-cover bottom-0 h-full left-0 absolute opacity-0 right-0 top-0 transition-all w-full group-hover:opacity-100 z-[1]"
          style={{
            ...(bundle.thumbnailUrlHover && {
              backgroundImage: `url(${optimizeImage(
                bundle.thumbnailUrlHover,
                600
              )})`,
            }),
          }}
        />
      </a>
      <div className="px-4 md:flex md:flex-col grow p-4">
        <h3>
          <a
            className="font-extrabold text-2xl mb-1 uppercase"
            dangerouslySetInnerHTML={{
              __html: bundle?.name ?? "Name not set",
            }}
            href={url}
          />
        </h3>
        <p
          className="font-serif text-base italic tracking-wide mb-4"
          dangerouslySetInnerHTML={{
            __html: bundle?.description ?? "Description not set",
          }}
        />
        <CardPrice
          retailPrice={bundle.priceRetail}
          salePrice={bundle.priceOnSale}
        />

        <div>
          <Button
            className={["w-full"]}
            size="small"
            text="Shop"
            type="borderBlack"
            url={url}
          />
        </div>
      </div>
    </div>
  )
}
