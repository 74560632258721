import { animated, useSpring } from "react-spring"

import Button from "@src/components/Button"

import optimizeImage from "@src/utilities/optimize-image"

export interface ColorfulContentBlockProps {
  content?: {
    background_color?: {
      color?: string
    }
    text_color?: {
      color?: string
    }
    button_text?: string
    button_url?: {
      cached_url?: string
      url?: string
    }
    content?: string
    flourish_image_left?: {
      filename?: string
    }
    flourish_image_right?: {
      filename?: string
    }
    section_title?: string
  }
}

export default function ColorfulContentBlock({
  content,
}: ColorfulContentBlockProps) {
  const hasButton = !!(
    (content?.button_text &&
      content.button_text.length > 0 &&
      content?.button_url?.url &&
      content.button_url.url.length > 0) ||
    (content?.button_url?.cached_url &&
      content.button_url.cached_url.length > 0)
  )

  const parallaxStyles = useSpring({
    from: {
      y: 0,
    },
    to: {
      y: 100,
    },
    config: {
      duration: 30000,
    },
    loop: { reverse: true },
  })

  const reverseParallaxStyles = useSpring({
    from: {
      y: 0,
    },
    to: {
      y: -50,
    },
    config: {
      duration: 30000,
    },
    loop: { reverse: true },
  })

  return (
    <section
      className="mb-8 md:mb-16 relative"
      data-component="colorful-content-block"
      style={{ backgroundColor: content?.background_color?.color ?? "#fff" }}
    >
      <div
        className="container-tablet"
        style={{ color: content?.text_color?.color ?? "#000" }}
      >
        <div className="py-8 md:py-16 text-center">
          {content?.section_title && (
            <p
              className="text-base font-bold uppercase tracking-wide"
              dangerouslySetInnerHTML={{ __html: content.section_title }}
            />
          )}
          {content?.content && (
            <p
              className="text-2xl md:text-4xl leading-relaxed font-serif"
              dangerouslySetInnerHTML={{ __html: content.content }}
            />
          )}
          {content?.content && hasButton && (
            <hr
              className="block h-1 mt-8 mb-12 mx-auto w-24"
              style={{ backgroundColor: content?.text_color?.color ?? "#000" }}
            />
          )}
          {hasButton && (
            <div>
              <Button
                text={content!.button_text}
                url={
                  content?.button_url?.url ||
                  `/${content.button_url?.cached_url}`
                }
                type="transparentBlack"
              />
            </div>
          )}
        </div>
      </div>
      {content?.flourish_image_left?.filename &&
        content?.flourish_image_left?.filename?.length > 0 && (
          <animated.div
            className="bg-right bg-contain absolute bg-no-repeat w-36 lg:w-52 -z-[1] lg:z-[1] bottom-8 left-0 before:content-[''] before:block before:pt-[100%]"
            style={{
              backgroundImage: `url(${optimizeImage(
                content.flourish_image_left.filename,
                600
              )})`,
              ...parallaxStyles,
            }}
          />
        )}
      {content?.flourish_image_right?.filename &&
        content?.flourish_image_right?.filename?.length > 0 && (
          <animated.div
            className="bg-left bg-contain absolute bg-no-repeat w-36 lg:w-52 -z-[1] lg:z-[1] top-0 right-0 before:content-[''] before:block before:pt-[100%]"
            style={{
              backgroundImage: `url(${optimizeImage(
                content.flourish_image_right.filename,
                600
              )})`,
              ...reverseParallaxStyles,
            }}
          />
        )}
    </section>
  )
}
