import React from "react"
import { animated, useTransition, UseTransitionProps } from "react-spring"
import { useDrag } from "react-use-gesture"
import { BiRadioCircle, BiRadioCircleMarked } from "react-icons/bi"
import { BsChevronLeft, BsChevronRight } from "react-icons/bs"

import StoryblokClient from "storyblok-js-client"
import urlFromStoryblokLinkBlok, {
  CmsLinkBlok,
} from "@src/utilities/url-from-storyblok-link-blok"
import Button from "@src/components/Button"

import classNames from "@src/utilities/class-names"
import optimizeImage from "@src/utilities/optimize-image"

const defaultTransition = {
  from: { opacity: 0, transform: "scale(0.95)" },
  enter: { opacity: 1, transform: "scale(1)" },
  leave: { opacity: 0, transform: "scale(1.05)" },
}

export interface SliderProps {
  arrowClassName?: string
  arrowsClassName?: string
  content: {
    slides: {
      _uid: string
      button: {
        button_style: string
        button_text: string
        button_url: CmsLinkBlok
      }[]
      featured_image_large: {
        filename: string
      }
      featured_image_small: {
        filename: string
      }
      headline: string
    }[]
  }
  dotClassName?: string
  dotsClassName?: string
  sliderClassName?: string
  transition?: UseTransitionProps
}

export default function Slider({
  content,
  sliderClassName,
  transition = defaultTransition,
}: SliderProps) {
  const [currentSlideIndex, setCurrentSlideIndex] = React.useState<number>(0)

  const transitions = useTransition(
    content.slides[currentSlideIndex],
    transition
  )

  const goToNextSlide = () =>
    setCurrentSlideIndex((prevState) =>
      prevState === content.slides.length - 1 ? 0 : prevState + 1
    )

  const goToPreviousSlide = () =>
    setCurrentSlideIndex((prevState) =>
      prevState === 0 ? content.slides.length - 1 : prevState - 1
    )

  const bindDrag = useDrag(
    ({ down, movement: [mx] }) => {
      if (down) {
        return
      }
      if (mx < 0) {
        goToPreviousSlide()
      } else {
        goToNextSlide()
      }
    },
    { axis: "x" }
  )

  return (
    <div
      className={
        sliderClassName ??
        `overflow-hidden relative mx-auto mb-8 max-w-screen-2xl min-h-[80vh] md:mb-16 md:min-h-[75vh]`
      }
      data-component="slider"
    >
      {transitions((springStyle, item) => (
        <animated.div
          {...bindDrag()}
          className={classNames(
            "absolute bottom-0 left-0 h-full right-0 origin-center top-0 w-full z-[1]"
          )}
          data-halign={item.horizontal_alignment}
          data-valign={item.vertical_alignment}
          key={`slide-${item._uid}`}
          style={springStyle}
        >
          <div
            className={classNames(
              "absolute bg-center bg-cover bg-no-repeat h-full top-0 left-0 right-0 bottom-0 w-full",
              item.horizontal_alignment === "left"
                ? "lg:bg-right"
                : "lg:bg-left"
            )}
            style={{
              ...(item?.featured_image_small?.filename && {
                backgroundImage: `url(${optimizeImage(
                  item.featured_image_large.filename,
                  2400
                )})`,
              }),
            }}
          >
            <div
              className={classNames(
                "bg-center bg-cover flex h-full justify-center text-center lg:items-center",
                item.vertical_alignment === "middle" ? "items-center" : "",
                item.vertical_alignment === "bottom" ? "items-end" : "",
                item.vertical_alignment === "top" ? "items-start" : "",
                item.horizontal_alignment === "left"
                  ? "lg:justify-start lg:text-left"
                  : "",
                item.horizontal_alignment === "right"
                  ? "lg:justify-end lg:text-left"
                  : "",
                item.horizontal_alignment === "center" ? "lg:items-center" : ""
              )}
              data-horizontal-alignment={item.horizontal_alignment}
              data-veritcal-alignment={item.vertical_alignment}
              id="mobile-background"
            >
              <style jsx>{`
                #mobile-background {
                  background-image: url("${item.featured_image_small
                    .filename}");
                }
                @media (min-width: 1024px) {
                  #mobile-background {
                    background-image: none;
                  }
                }
              `}</style>
              <div className="p-8 lg:w-1/2">
                <h1 className="font-extrabold text-2xl lg:text-5xl lg:leading-tight uppercase">
                  {item.headline}
                </h1>
                <div
                  className="md:text-lg"
                  dangerouslySetInnerHTML={{
                    __html: new StoryblokClient({}).richTextResolver.render(
                      item.content
                    ),
                  }}
                />
                {item.button && item.button.length === 1 && (
                  <Button
                    className={["mt-8"]}
                    text={item.button[0].button_text}
                    type={item.button[0].button_style}
                    url={urlFromStoryblokLinkBlok(item.button[0].button_url)}
                  />
                )}
              </div>
            </div>
          </div>
        </animated.div>
      ))}
      {content.slides.length > 1 && (
        <div className="absolute bottom-0 right-0 z-[2] flex text-xs pr-3 md:hidden">
          {content.slides.map((_: any, idx: number) => (
            <div
              aria-current={idx === currentSlideIndex}
              aria-label={`Slide ${currentSlideIndex + 1} of ${
                content.slides.length
              }`}
              className="py-2 text-center transition-colors"
              data-slider-slide-active={idx === currentSlideIndex}
              key={`dot-${idx}`}
            >
              {idx === currentSlideIndex ? (
                <BiRadioCircleMarked />
              ) : (
                <BiRadioCircle />
              )}
            </div>
          ))}
        </div>
      )}
      {content.slides.length > 1 && (
        <div className="absolute bottom-0 right-0 z-[2] flex text-xs hidden md:block">
          <button
            aria-label="Go to previous slide"
            className="bg-black text-white p-4 transition-colors border-right-black text-lg hover:bg-orange"
            data-arrow="prev"
            onClick={() => goToPreviousSlide()}
          >
            <BsChevronLeft />
          </button>
          <button
            aria-label="Go to next slide"
            className="bg-black text-white p-4 transition-colors border-left-black text-lg hover:bg-orange"
            data-arrow="next"
            onClick={() => goToNextSlide()}
          >
            <BsChevronRight />
          </button>
        </div>
      )}
    </div>
  )
}
