import React from "react"
import Button from "@src/components/Button"
import _round from "lodash/round"

import useGtm from "@src/hooks/use-gtm"

import { formatCurrency } from "@src/utilities/format-numbers"

import type { BundleProductCard } from "@src/entities/models/bundle-product-card"

interface CardPriceProps {
  retailPrice: number
  salePrice?: number | null
  subscriptionPrice?: number | null
}
function CardPrice({
  retailPrice,
  salePrice,
  subscriptionPrice,
}: CardPriceProps) {
  if (!retailPrice) {
    return null
  }
  if (salePrice && salePrice > 0 && salePrice < retailPrice) {
    // The product is on sale store-wide
    return (
      <p
        className="font-extrabold mb-2 text-base"
        dangerouslySetInnerHTML={{
          __html: `${formatCurrency(
            salePrice
          )} <span class="ml-2 font-normal text-sm line-through">${formatCurrency(
            retailPrice
          )}</span>`,
        }}
      />
    )
  }
  if (
    subscriptionPrice &&
    subscriptionPrice > 0 &&
    salePrice &&
    salePrice > 0 &&
    salePrice > subscriptionPrice
  ) {
    return (
      <p
        className="font-extrabold mb-2 text-base"
        dangerouslySetInnerHTML={{
          __html: `${formatCurrency(
            subscriptionPrice
          )} &ndash; ${formatCurrency(salePrice)}`,
        }}
      />
    )
  }
  return (
    <p
      className="font-extrabold mb-2 text-base"
      dangerouslySetInnerHTML={{
        __html: formatCurrency(
          salePrice && salePrice > 0 ? salePrice : retailPrice
        ),
      }}
    />
  )
}

export interface ProductCardProps {
  product?: BundleProductCard["product"]
  url?: string
}
export default function ProductCard({ product, url }: ProductCardProps) {
  if (!product) {
    return null
  }

  return (
    <div
      className={[
        "group flex flex-col justify-start transition-colors border border-transparent rounded-sm hover:border-gray-300",
      ].join(" ")}
      data-component="product-card"
    >
      <a
        href={product.amazonUrl || url}
        aria-label={product?.name ?? ""}
        className="bg-center bg-cover block relative before:content-[''] before:block before:pt-[100%]"
        style={{
          backgroundImage: `url(${
            product?.thumbnailUrl || "/images/placeholder.jpeg"
          })`,
        }}
        target={product.amazonUrl ? "_blank" : "_self"}
      >
        <div
          className="bg-center bg-cover bottom-0 h-full left-0 absolute opacity-0 right-0 top-0 transition-all w-full group-hover:opacity-100 z-[1]"
          style={{
            backgroundImage: `url(${
              product.thumbnailUrl || "/images/placeholder.jpeg"
            })`,
          }}
        />
        {product.amazonUrl && (
          <div className="absolute bg-yellow-400 px-4 py-2 top-2 right-2 w-28 z-[2]">
            <img src="/images/available_at_amazon.png" className="block" />
          </div>
        )}
      </a>
      <div className="px-4 md:flex md:flex-col grow p-4">
        <h3>
          <a
            className="font-extrabold text-2xl mb-1 uppercase"
            dangerouslySetInnerHTML={{
              __html: product?.name ?? "Name not set",
            }}
            href={url}
          />
        </h3>
        <p
          className="font-serif text-base italic tracking-wide mb-4"
          dangerouslySetInnerHTML={{
            __html: product?.description ?? "Description not set",
          }}
        />
        {product?.amazonUrl && (
          <Button
            className={["w-full"]}
            openInNewWindow={true}
            size="small"
            text="Shop Now on Amazon"
            type="borderBlack"
            url={product.amazonUrl}
          />
        )}
        {!product?.amazonUrl && (
          <>
            {product?.name && product?.sku && (
              <div className="mb-4 md:grow">
                <div
                  className="yotpo bottomLine"
                  data-appkey={process.env.NEXT_PUBLIC_YOTPO_APP_KEY}
                  data-domain={process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}
                  data-product-id={product.sku}
                  data-name={product.name}
                  data-url={url}
                  data-description={product.description}
                />
              </div>
            )}
            <CardPrice
              retailPrice={product.priceRetail}
              salePrice={product.priceOnSale}
              subscriptionPrice={product.priceSubscription}
            />
            <div>
              <Button
                className={["w-full"]}
                onClick={() => {
                  useGtm({
                    event: "orTrack",
                    eventName: "Product Clicked",
                    properties: {
                      ...(product.thumbnailUrl && {
                        image_url: product.thumbnailUrl,
                      }),
                      name: product?.name,
                      price: _round(
                        0.01 * (product.priceOnSale ?? product.priceRetail),
                        2
                      ),
                      product_id: product?.sku,
                      quantity: 1,
                      sku: product?.sku,
                      ...(product.description && {
                        variant: product.description,
                      }),
                    },
                  })
                }}
                size="small"
                text="Shop"
                type="borderBlack"
                url={url}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
