import React from "react"
import { Formik, Field, Form } from "formik"
import Button from "@src/components/Button"

import type { LabCertificateSearchResult } from "@src/entities/types/api"

import searchLabCertificates from "@src/pages/api/search-lab-certificates"

import styles from "./index.module.scss"

export interface LabCertificateSearchProps {
  content: {
    container_width?: {
      containerWidth?: string
    }
    title?: string
  }
}

export default function LabCertificateSearch({
  content,
}: LabCertificateSearchProps) {
  const [certificates, setCertificates] = React.useState<
    LabCertificateSearchResult[]
  >([])
  const [error, setError] = React.useState<string | null>("")

  return (
    <div
      className={
        content.container_width?.containerWidth
          ? `container-${content.container_width?.containerWidth}`
          : "container-website"
      }
      id={styles.container}
    >
      <div id={styles.wrapper}>
        {content.title && (
          <h3 dangerouslySetInnerHTML={{ __html: content.title }} />
        )}
        <Formik
          enableReinitialize={true}
          initialValues={{
            searchText: "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            setError(null)
            setCertificates([])
            searchLabCertificates(values.searchText).then((result) => {
              setSubmitting(false)
              if (result.error) {
                setError(result.error)
                return
              }
              setCertificates(result.data ?? [])
            })
          }}
          validateOnBlur={true}
          validateOnChange={false}
        >
          {(formik) => (
            <Form className="form" id={styles.form}>
              <fieldset disabled={formik.isSubmitting}>
                <div className="form-row">
                  <div className="form-column form-column-two-thirds">
                    <Field
                      name="searchText"
                      placeholder="Enter batch number"
                      type="text"
                    />
                  </div>
                  <div className="form-column form-column-third">
                    <Button
                      onClick={() =>
                        formik.validateForm().then(() => formik.handleSubmit())
                      }
                      text="Search"
                    />
                  </div>
                </div>
              </fieldset>
            </Form>
          )}
        </Formik>
      </div>
      <div id={styles.searchResults}>
        {error && (
          <div
            id={styles.noneFound}
            dangerouslySetInnerHTML={{ __html: error }}
          />
        )}
        <ol>
          {certificates.map((certificate, idx) => (
            <li key={`certificate-${idx}`}>
              <a href={certificate.url} download>
                {certificate.name}
              </a>
            </li>
          ))}
        </ol>
      </div>
    </div>
  )
}
