import { SEARCH_LAB_CERTIFICATES } from "@src/operations/api/lab-certificates"
import apolloClient from "@src/utilities/apollo-client"

import type { CertificateSearchResult } from "@src/entities/types/lab-certificates"
import type {
  SearchLabCertificatesQuery,
  SearchLabCertificatesQueryVariables,
} from "@src/entities/types/api"

interface RearchLabCertificatesResponse {
  data?: CertificateSearchResult[]
  error?: string
}
export default async function searchLabCertificates(
  searchText: string
): Promise<RearchLabCertificatesResponse> {
  return apolloClient
    .query<SearchLabCertificatesQuery, SearchLabCertificatesQueryVariables>({
      query: SEARCH_LAB_CERTIFICATES,
      variables: {
        input: searchText,
      },
    })
    .then(({ data, errors }) => {
      if (errors) {
        return {
          error: errors.map((err) => err.message).join(""),
        }
      }
      return {
        data: data.storyblokSearch,
      }
    })
}
