interface HeadlineWithUnderlineProps {
  content: {
    container_width?: {
      containerWidth?: string
    }
    headline: string
  }
}
export default function HeadlineWithUnderline({
  content,
}: HeadlineWithUnderlineProps) {
  return (
    <div
      className={`container-${
        content.container_width?.containerWidth ?? "tablet"
      }`}
      data-component="headline-with-underline"
    >
      <h3 className="border-b border-b-gray-300 font-bold mb-16 pb-6 text-2xl uppercase">
        {content.headline}
      </h3>
    </div>
  )
}
