import FAQItem, { FAQItemProps } from "@src/components/FAQItem"

import styles from "./index.module.scss"

export interface FAQBlockProps {
  content?: {
    container_width?: {
      containerWidth?: string
    }
    faq_list?: FAQItemProps["content"][]
    title?: string
  }
}

export default function FAQBlock({ content }: FAQBlockProps) {
  return (
    <section
      className={`container-${
        content?.container_width?.containerWidth ?? "laptop"
      }`}
    >
      <div className={styles.wrapper}>
        {content?.title && (
          <h2
            className="font-extrabold uppercase text-3xl"
            dangerouslySetInnerHTML={{ __html: content.title }}
          />
        )}
        <div className={styles.faqList}>
          {content?.faq_list?.map((faq, idx) => (
            <FAQItem content={faq} key={`faq-${idx}`} />
          ))}
        </div>
      </div>
    </section>
  )
}
