// @ts-nocheck
import SbEditable from "storyblok-react"

import FAQBlock from "@src/components/FAQBlock"
import FAQPage from "@src/components/FAQPage"
import FullScreenImage from "@src/components/FullScreenImage"
import HeadlineWithUnderline from "@src/components/HeadlineWithUnderline"
import IconBlock from "@src/components/IconBlock"
import IconRow from "@src/components/IconRow"
import IconSplitBlock from "@src/components/IconSplitBlock"
import IngredientsList from "@src/components/IngredientsList"
import KlaviyoForm from "@src/components/KlaviyoForm"
import ColorfulContentBlock from "@src/components/ColorfulContentBlock"
import LabCertificateSearch from "@src/components/LabCertificateSearch"
import LogoReel from "@src/components/LogoReel"
import ProductGrid from "@src/components/ProductGrid"
import ProfessionalReview from "@src/components/ProfessionalReview"
import ReviewsSlider from "@src/components/ReviewsSlider"
import Slider from "@src/components/Slider"
import ScriptBlock from "@src/components/ScriptBlock"
import SplitBlock from "@src/components/SplitBlock"
import SplitBlockCTA from "@src/components/SplitBlockCTA"
import SplitHeader from "@src/components/SplitHeader"
import TabbedList from "@src/components/TabbedList"
import WYSIWYGContent from "@src/components/WYSIWYGContent"

const Components: { [key: string]: any } = {
  call_to_action: SplitBlockCTA,
  colorful_content_block: ColorfulContentBlock,
  faq_block: FAQBlock,
  faq_page: FAQPage,
  full_screen_image: FullScreenImage,
  headline_with_underline: HeadlineWithUnderline,
  hero: Slider,
  icon_block: IconBlock,
  icon_row: IconRow,
  icon_split_block: IconSplitBlock,
  ingredients_list: IngredientsList,
  klaviyo_form: KlaviyoForm,
  lab_certificate_search: LabCertificateSearch,
  logo_reel: LogoReel,
  professional_review: ProfessionalReview,
  product_grid: ProductGrid,
  reviews_slider: ReviewsSlider,
  split_block: SplitBlock,
  split_header: SplitHeader,
  script_block: ScriptBlock,
  tabbed_list: TabbedList,
  wysiwyg: WYSIWYGContent,
}

function defaultWarningMessage(component: string): string {
  return `The component <strong>${component}</strong> has not been created yet, or you have selected a component that can only be used as the child of a larger component (eg, <em>hero_slide</em> must be used inside a <em>hero</em> component)`
}

const warningMessage: { [key: string]: string } = {
  product_card:
    "A <em>Product Card</em> can only be used inside a <em>Product Grid</em> component. Please add a <em>Product Grid</em> component and then add your <em>Product Card</em> to that.",
}

interface PageBuilderProps {
  content: onyx.Types.StoryblokContent
}
export default function PageBuilder({ content }: PageBuilderProps) {
  if (typeof Components[content.component] !== "undefined") {
    const Component = Components[content.component]

    return (
      <SbEditable content={content}>
        <Component content={content} />
      </SbEditable>
    )
  }
  const displayText = warningMessage[content.component]
    ? warningMessage[content.component]
    : defaultWarningMessage(content.component)
  return (
    <div
      className="mx-auto max-w-7xl"
      style={{ padding: `30px 0` }}
      dangerouslySetInnerHTML={{ __html: displayText }}
    />
  )
}
