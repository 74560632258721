import _get from "lodash/get"

import optimizeImage from "@src/utilities/optimize-image"

import classNames from "@src/utilities/class-names"

const imgClasses = classNames("block mx-auto max-w-full w-full")

function ImageTag({ className, image }: { className?: string; image: any }) {
  if (_get(image, "filename", "") === "") {
    return null
  }
  return (
    <img
      alt={_get(image, "alt")}
      className={classNames(imgClasses, className ?? "")}
      src={optimizeImage(_get(image, "filename"), 1600)}
    />
  )
}

interface FullScreenImageProps {
  content: {
    background_image: {
      filename: string
    }
    desktop_image: {
      alt: string | null
      filename: string
    }
    mobile_image: {
      alt: string | null
      filename: string
    }
  }
}
export default function FullScreenImage({ content }: FullScreenImageProps) {
  if (
    _get(content, "mobile_image.filename", "") === "" &&
    _get(content, "desktop_image.filename", "") === ""
  ) {
    return (
      <div className="mb-8 md:mb-16">
        <ImageTag image={content.background_image} />
      </div>
    )
  }
  return (
    <div
      className="bg-center bg-cover py-8 md:py-16 mb-8 md:mb-16"
      data-component="full-screen-image"
      style={{
        backgroundImage: `url(${optimizeImage(
          content.background_image.filename,
          1600
        )})`,
      }}
    >
      <div className="container-laptop">
        {content.desktop_image && (
          <ImageTag className="hidden md:block" image={content.desktop_image} />
        )}
        {content.mobile_image && (
          <ImageTag className="md:hidden" image={content.mobile_image} />
        )}
      </div>
    </div>
  )
}
