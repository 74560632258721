import optimizeImage from "@src/utilities/optimize-image"

export interface IconRowProps {
  content?: {
    container_width?: {
      containerWidth?: string
    }
    headline?: string
    icons?: {
      icon?: {
        filename?: string
      }
      label?: string
    }[]
    preheadline?: string
  }
}

export default function IconRow({ content }: IconRowProps) {
  return (
    <section
      className={`container-${
        content?.container_width?.containerWidth ?? "laptop"
      }`}
      data-component="icon-row"
    >
      <div className="mb-8 md:mb-16 lg:items-start lg:flex lg:justify-between">
        <div className="text-center lg:pr-8 lg:text-left">
          {content?.preheadline && (
            <p
              className="italic font-serif mb-1 text-lg"
              dangerouslySetInnerHTML={{ __html: content.preheadline }}
            />
          )}
          {content?.headline && (
            <h5
              className="border-b-4 border-black inline-block font-extrabold text-3xl uppercase mx-auto mb-2 md:border-b-8 lg:mb-0"
              dangerouslySetInnerHTML={{ __html: content.headline }}
            />
          )}
        </div>
        <div className="items-start flex flex-wrap justify-between md:flex-nowrap lg:flex-1">
          {content?.icons?.map((icon, idx) => (
            <div
              key={`icon_row_${idx}`}
              className="flex-[1_0_50%] mt-4 text-center md:flex-1 lg:mt-0"
            >
              <img
                className="mb-2 mx-auto"
                src={optimizeImage(icon.icon?.filename, 300)}
              />
              <div
                className="font-sans text-sm whitespace-nowrap lg:whitespace-normal"
                dangerouslySetInnerHTML={{ __html: icon.label ?? "" }}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
