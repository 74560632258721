import { gql } from "@apollo/client"

export default {}

export const SEARCH_LAB_CERTIFICATES = gql`
  query SearchLabCertificates($input: String!) {
    storyblokSearch(input: $input) {
      name
      url
    }
  }
`
