import React from "react"

export interface WYSIWYGContentProps {
  content: onyx.Types.StoryblokContent
}
export default function ScriptBlock({ content }: WYSIWYGContentProps) {
  return (
    <div
      className={content.wrap_in_container && "container-tablet"}
      dangerouslySetInnerHTML={{
        __html: content.script,
      }}
      data-component="script-block"
    />
  )
}
