import { BsPlus } from "react-icons/bs"

import FAQItem, { type FAQItemProps } from "@src/components/FAQItem"

export interface FAQPageProps {
  content?: {
    faq_list?: FAQItemProps[]
    stamp_image?: {
      filename?: string
    }
    title?: string
  }
}

export default function FAQPage({ content }: FAQPageProps) {
  return (
    <div className="container-tablet" data-component="faq-page">
      <header className="flex items-center justify-between pb-16">
        <h1 className="flex flex-1 font-extrabold text-3xl lg:text-5xl uppercase">
          <span className="lg:-mt-2">
            <BsPlus />
          </span>
          {content?.title}
        </h1>
        {content?.stamp_image?.filename && (
          <img
            className="hidden md:block max-w-[5rem]"
            src={content?.stamp_image?.filename}
          />
        )}
      </header>
      <div className="border-t border-gray-300 mb-16">
        {content?.faq_list?.map((faq, idx) => (
          <FAQItem content={faq.content} key={`faq-${idx}`} />
        ))}
      </div>
    </div>
  )
}
