import { useSpring, animated } from "react-spring"

export interface LogoReelProps {
  content?: {
    container_width?: {
      containerWidth?: string
    }
    background_color?: {
      color?: string
    }
    flourish_image_left?: {
      filename?: string
    }
    flourish_image_right?: {
      filename?: string
    }
    headline?: string
    logos?: {
      filename?: string
    }[]
  }
}

export default function LogoReel({ content }: LogoReelProps) {
  const parallaxStyles = useSpring({
    from: {
      y: 0,
    },
    to: {
      y: 50,
    },
    config: {
      duration: 30000,
    },
    loop: { reverse: true },
  })
  return (
    <section className="overflow-hidden" data-component="logo-reel">
      <div
        className={`container-${
          content?.container_width?.containerWidth ?? "website"
        }`}
      >
        <div
          className="bg-gray-400 p-8 relative mb-8 md:pb-12 md:mb-16"
          style={{
            ...(content?.background_color?.color && {
              backgroundColor: content.background_color.color,
            }),
          }}
        >
          <div className="lg:py-8">
            {content?.headline && (
              <p
                className="font-serif italic mb-4 lg:mb-8 text-center text-lg"
                dangerouslySetInnerHTML={{ __html: content?.headline }}
              />
            )}
            {content?.logos && content.logos.length > 0 && (
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 lg:gap-8 content-center items-center place-items-center">
                {content.logos.map((logo, idx) => (
                  <img key={`logo_reel_${idx}`} src={logo.filename} />
                ))}
              </div>
            )}
          </div>
          {content?.flourish_image_left?.filename &&
            content.flourish_image_left.filename.length > 0 && (
              <animated.div
                className="bg-right bg-contain bg-no-repeat absolute z-[-1] w-44 -left-24 top-0 before:content-[''] before:block before:pt-[100%] lg:bg-left lg:z-[1] lg:left-auto lg:right-full"
                style={{
                  backgroundImage: `url(${content.flourish_image_left.filename})`,
                  ...parallaxStyles,
                }}
              />
            )}
          {content?.flourish_image_right?.filename &&
            content.flourish_image_right.filename.length > 0 && (
              <animated.div
                className="bg-left bg-contain bg-no-repeat absolute z-[-1] w-44 -right-24 bottom-0 before:content-[''] before:block before:pt-[100%] lg:bg-right lg:z-[1] lg:right-auto lg:left-full"
                style={{
                  backgroundImage: `url(${content.flourish_image_right.filename})`,
                  ...parallaxStyles,
                }}
              />
            )}
        </div>
      </div>
    </section>
  )
}
