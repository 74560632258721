import React from "react"

interface UseHeightProps {
  on?: boolean
}

export default function useHeight(
  { on = true }: UseHeightProps = { on: true }
) {
  const ref = React.useRef<any>()

  const [height, setHeight] = React.useState<number>(0)

  const heightRef = React.useRef<number>(height)

  const [ro, setRo] = React.useState<any>(null)

  React.useEffect(() => {
    setRo(
      () =>
        new ResizeObserver(() => {
          if (ref.current && heightRef.current !== ref.current.offsetHeight) {
            heightRef.current = ref.current.offsetHeight
            setHeight(ref.current.offsetHeight)
          }
        })
    )
  }, [])

  React.useEffect(() => {
    if (on && ref.current && ro) {
      setHeight(ref.current.offsetHeight)
      ro.observe(ref.current, {})
    }
    return () => ro && ro.disconnect()
  }, [on, ref.current, ro])

  return [ref, height as any]
}
