export default function optimizeImage(
  src: string = "",
  maxWidth: number
): string {
  if (!src || !src.includes("a.storyblok.com")) {
    return src
  }
  const type = src.split(".").pop()
  if (type === "svg") {
    return src
  }
  return `${src}/m/${maxWidth}x0`
}
